import React from 'react';
import { Link } from 'gatsby';
import { useCart } from '../../../hooks/useCart';
import { toast } from 'react-toastify';

import CartItemList from './cartItemList';
import ReedmakerConfig from "../../../../content/reedmaker.yaml"

function Cart() {
  const { content } = ReedmakerConfig
  const { isEmpty, cartTotal, inCart, addItem, hasBassoonItem, isRushOrderAllowed  } = useCart();
  const formattedSubTotal = "$" + cartTotal.toFixed(2)

  const addRushOrder = () => {
    if (!inCart(content.rushOrder.id) && isRushOrderAllowed(false)) {
        addItem({ 
          id: content.rushOrder.id, 
          price: content.rushOrder.price, 
          image: undefined, 
          name: content.rushOrder.name, 
          hardness: undefined,
        }, 1 )
      } else {
        toast.info(`You can only have at most ${content.cart.rushOrder.reedLimit} reeds to add a rush order. Please order less reeds.`)
      }
  }

  if (isEmpty) return <p>Your cart is empty</p>;

  return (
    <React.Fragment>
      <p>{content.cart.checkout.description}</p>
      {hasBassoonItem && <p className='py-2'>{content.cart.checkoutBassoon.description}</p>}
      { (content.cart.rushOrder.on && !hasBassoonItem)&&
        <p>
          {content.cart.rushOrder.description}
          <button
            className="block bg-red-800 hover:bg-slategray px-2 py-2 rounded-lg text-white text-sm font-bold uppercase focus:outline-none"
            onClick={() => { addRushOrder()
            }}
          >
            {content.cart.rushOrder.button}
          </button>
        </p>
      }

      <CartItemList />

      <div className="mt-3 md:mt-6 py-3 md:py-6 border-t-2 border-gainsboro">
        <div className="flex flex-col items-end">
          <div className="flex flex-col items-end mb-3">
            <span className="text-slategray">Sub total</span>
            <span className="text-xl font-bold text-primary">
              {formattedSubTotal}
            </span>
          </div>

          <Link
            to="/checkout"
            className="bg-red-800 px-4 py-3 rounded-lg text-white text-sm font-bold tracking-widest uppercase focus:outline-none"
          >
            Checkout
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Cart;
