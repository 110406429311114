import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Cart from '../components/store/cart/cart'

const CartPage = () => (
  <Layout>
    <div className="container mx-auto p-6 py-16">
        <div className="flex flex-wrap">
            <SEO title="cart" />

            <section className={`w-full p-3`}>                  
                <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary">
                    Your Cart
                </h1>
                <div id="main-content-block">
                    <Cart />
                </div>
            </section>

        </div>
    </div>
  </Layout>
)

export default CartPage
